import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel";
import banner from "../assets/images/banner/banner.png"; // this is the main image
import testimonials1 from "./testimonial-1.jpg";
import testimonials2 from "./testimonial-2.jpg";
import Form from "react-bootstrap/Form";
// import two from "../assets/images/homebanner.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Carousel } from "react-bootstrap";
import Slider from "react-slick";
import one from "./headerImages/one.png";
import two from "./headerImages/two.png";
import three from "./headerImages/three.png";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadimg();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow2(false);
  const handleShow = () => setShow2(true);

  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    description: "",
  });

  const handleChange = (e) => {
    const myForm = { ...form };
    myForm[e.target.name] = e.target.value;
    setForm(myForm);
  };

  const addForm = (e) => {
    e.preventDefault();
    const bodydata = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      location: form.location,
      description: form.description,
      currentBusiness: form.currentBusiness,
    };
    axios
      .post(
        "https://api.kanavneer.com/v1/kanavneelapi/web/enquiryform/addenquiry",
        bodydata
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setForm({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              description: "",
              currentBusiness: "",
            });
            handleClose();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          }
        }
      );
  };

  const loadimg = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const textColor = {
    color: "#002c8f",
  };

  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    // arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      {/* <Sidebar /> */}
      <div class="boxed_wrapper">
        {/* <section className="banner-section">
          <div
            className="shape"
            style={{
              backgroundImage: "url(assets/images/shape/banner-shap.png)",
            }}
          />
          <OwlCarousel
            className="banner-carousel owl-theme owl-carousel owl-dots-none"
            items={1}
            loop
            autoplay
          >
            <div className="slide-item">
              <div className="pattern-box">
                <div className="pattern-1" />
                <div className="pattern-2" />
                <div
                  className="pattern-3"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-2.png)",
                  }}
                />
              </div>
              <div className="auto-container">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image image-1">
                      <img
                        src={banner}
                        alt="waterfilter"
                        style={{
                          height: "500px",
                          position: "relative",
                          right: "100px",
                          bottom: "75px",
                        }}
                      />
                    </figure>
                  </div>
                  <div className="content-box">
                    <h2>Always Want Safe and Good Water for Healthy Life</h2>
                    <p>
                      Our Family safe and healthy, it is equally essential to
                      filter water before drinking it.
                    </p>
                    <div className="btn-box clearfix">
                      <a
                        href="/contactus"
                        className="theme-btn banner-btn border-white"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item">
              <div className="pattern-box">
                <div className="pattern-1" />
                <div className="pattern-2" />
                <div
                  className="pattern-3"
                  style={{
                    backgroundImage: "url(assets/images/shape/shape-2.png)",
                  }}
                />
              </div>
              <div className="auto-container">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image image-1">
                      <img
                        src={homebanner}
                        alt=""
                        width="700px"
                        height="500px"
                      />
                    </figure>
                  </div>
                  <div className="content-box">
                    <h2>Always Want Safe and Good Water for Healthy Life</h2>
                    <p>
                      Our Family safe and healthy, it is equally essential to
                      filter water before drinking it.
                    </p>
                    <div className="btn-box clearfix">
                      <a
                        href="/contactus"
                        className="theme-btn banner-btn border-white"
                      >
                        Discover
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </section> */}

        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img src={two} className="custom-img" alt="Image" />
            </div>
            <div>
              <img src={one} className="custom-img" alt="Image" />
            </div>
            <div>
              <img src={three} className="custom-img" alt="Image" />
            </div>
          </Slider>
        </div>

        {/* 2 CARDS  */}

        <section
          className="service-section bg-color-1 mb-2"
          id="service"
          style={{ marginTop: "-6px" }}
        >
          <h1 style={textColor} className="text-center">
            Our Plans
          </h1>
          <Modal show={show2} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title> Book Now</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ margin: "0px", background: "#ebf3fa" }}>
              <div className="faq-sidebar">
                <div className="form-inner">
                  <form
                    onSubmit={(e) => {
                      addForm(e);
                    }}
                  >
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="name"
                        value={form.name}
                        required
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="Enter Your Name"
                        style={borderColor}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        required
                        value={form.email}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="Enter Your Email"
                        style={borderColor}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="tel"
                        required
                        name="phoneNumber"
                        value={form.phoneNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="Enter Your Mobile Number"
                        style={borderColor}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        required
                        name="location"
                        placeholder="Enter Your 
                    location"
                        value={form.location}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={borderColor}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        placeholder="Message"
                        required
                        as="textarea"
                        rows={3}
                        name="description"
                        value={form.description}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={borderColor}
                      />
                    </Form.Group>
                    <div className="form-group message-btn">
                      <button
                        type="submit"
                        className="theme-btn btn-one"
                        style={{ width: "65%" }}
                      >
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <div className="auto-container">
            <div className="row justify-content-center align-items-stretch mt-5">
              {/* Pricing Card 1 */}
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                style={{ marginBottom: "10px", marginLeft: "12px" }}
              >
                <div className="pricing-block-one d-flex flex-column h-100">
                  <div className="pricing-table h-100 d-flex flex-column">
                    <div className="shape-layer" />

                    <div className="table-header">
                      <div className="price-box">
                        <div className="price-shape">
                          <div
                            className="shape-1"
                            style={{
                              backgroundImage:
                                "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-29.png)",
                            }}
                          />
                          <div
                            className="shape-2"
                            style={{
                              backgroundImage:
                                "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-30.png)",
                            }}
                          />
                        </div>
                        <h3>₹12999</h3>
                        {/* <span>Yearly</span> */}
                      </div>
                      <div className="title">
                        <h3 style={textColor}>Pro</h3>
                        <div>
                          <i
                            className="fas fa-star"
                            style={{ color: "gold" }}
                          ></i>
                          <b style={textColor}>One Time Fee</b>
                        </div>
                      </div>
                    </div>

                    <div className="table-content flex-grow-1 d-flex flex-column justify-between">
                      <ul className="feature-list clearfix">
                        <li>4 times general service free in a year</li>
                        <li>After 1 Year AMC 1999/-</li>
                        <li>1500/- worth alcone free</li>
                        <li>Up to 1 year parts replacements</li>
                      </ul>
                      <div className="table-footer mt-auto d-flex justify-content-center">
                        <a
                          className="theme-btn btn-one"
                          onClick={handleShow}
                          style={{ cursor: "pointer" }}
                        >
                          Book a Service
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Pricing Card 2 */}
              <div
                className="col-lg-4 col-md-6 col-12 d-flex"
                style={{
                  marginBottom: "10px",
                  marginLeft: "12px",
                  marginTop: "-15px",
                }}
              >
                <div className="pricing-block-one d-flex flex-column h-100">
                  <div className="pricing-table h-100 d-flex flex-column">
                    <div className="shape-layer" />

                    <div className="table-header">
                      <div className="price-box">
                        <div className="price-shape">
                          <div
                            className="shape-1"
                            style={{
                              backgroundImage:
                                "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-29.png)",
                            }}
                          />
                          <div
                            className="shape-2"
                            style={{
                              backgroundImage:
                                "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-30.png)",
                            }}
                          />
                        </div>
                        <h3>₹2999</h3>
                        <span
                          className="text-danger"
                          style={{ fontSize: "10px" }}
                        >
                          <b>Low Down Payment</b>
                          <p style={{ fontSize: "10px" }}>
                            <b>Per month ₹999/-</b>
                          </p>
                        </span>
                      </div>
                      <div className="title">
                        <h3 style={textColor}>Pro Plus</h3>
                        <div>
                          <i
                            className="fas fa-star"
                            style={{ color: "gold" }}
                          ></i>
                          <b style={textColor}>(EMI available)</b>
                        </div>
                      </div>
                    </div>

                    <div className="table-content flex-grow-1 d-flex flex-column justify-between">
                      <ul className="feature-list clearfix">
                        <li>4 times general service free in a year</li>
                        <li>After 1 Year AMC 1999/-</li>
                        <li>1500/- worth alcone free</li>
                        <li>Up to 1 year parts replacements</li>
                      </ul>
                      <div className="table-footer mt-5 d-flex justify-content-center">
                        <a
                          className="theme-btn btn-one"
                          onClick={handleShow}
                          style={{ cursor: "pointer" }}
                        >
                          Book a Service
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Kanavneer Services */}

        <section className="service-section " id="service">
          <div
            className="shape"
            style={{ backgroundImage: "url(assets/images/shape/shape-4.png)" }}
          />
          <figure className="image-layer">
            <img
              src={banner}
              alt="waterFilterImage"
              style={{ height: "520px", marginRight: "50px" }}
            />
          </figure>
          <div className="bg-shape">
            <div className="bg-shape-1" />
            <div className="bg-shape-2" />
            <div className="bg-shape-3" />
          </div>
          <div className="auto-container">
            <div className="sec-title centred">
              <h1 style={textColor}>Kanavneer Services</h1>
            </div>
            <div className="row clearfix">
              <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                <div className="left-column text-right">
                  <div
                    className="service-block-one wow fadeInLeft animated animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "0ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-bottle-1" />
                      </div>
                      <h4>
                        <a href="service-details-2.html">Residential Waters</a>
                      </h4>
                      <p>
                        Drinking water for your home with our advanced
                        purification systems.
                      </p>
                    </div>
                  </div>
                  <div
                    className="service-block-one wow fadeInLeft animated animated"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "300ms",
                      animationName: "fadeInLeft",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water" />
                      </div>
                      <h4>
                        <a href="service-details-4.html">Filtration Plants</a>
                      </h4>
                      <p>
                        Advanced filtration plants providing pure, safe water.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 big-column">
                <div className="right-column text-left">
                  <div
                    className="service-block-one wow fadeInRight animated animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "0ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-bottle" />
                      </div>
                      <h4>
                        <a href="service-details-3.html">Commercial Waters</a>
                      </h4>
                      <p>
                        Reliable water purification solutions for all your
                        needs.
                      </p>
                    </div>
                  </div>
                  <div
                    className="service-block-one wow fadeInRight animated animated"
                    data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationDelay: "300ms",
                      animationName: "fadeInRight",
                    }}
                  >
                    <div className="inner-box">
                      <div className="icon-box">
                        <i className="flaticon-water-drop-1" />
                      </div>
                      <h4>
                        <a href="service-details-5.html">Water Softening</a>
                      </h4>
                      <p>
                        Water softening to reduce hardness and improve water
                        quality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* HOW IT WORKS */}
        {/* <h2 className="text-center" style={textColor}>
          How it Works
        </h2>

        <div class="card-container">
          <div class="diddrtn">
            <i class="fas fa-cogs"></i>
            <h6>Select a Machine</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-list-alt"></i>
            <h6>Select a Plan</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-dollar-sign"></i>
            <h6>Pay the Bill as per Plan</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-truck"></i>
            <h6>Machine Door Delivery</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-wrench"></i>
            <h6>Fix at Home and Drink Healthy Water</h6>
          </div>
        </div> */}

        <div className="slider-container mb-4">
          <h2 className="text-center mb-3" style={textColor}>
            Testimonials
          </h2>
          <Slider {...settings1}>
            <section className="container mt-3">
              <div className="row justify-content-center">
                <div>
                  <div className="review-card d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="rounded-circle overflow-hidden user-image-container mr-3">
                          <img
                            src={one}
                            className="img-fluid"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="text-left">
                          <h5 className="mb-0">Sarah Williams</h5>
                          <small className="text-muted">@sarahwilliams</small>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                    <p className="text-muted mt-3">
                    Kannveer has improved our water quality immensely! The purifier provides clean, pure water, and the whole process from delivery to installation was hassle-free.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="container mt-3">
              <div className="row justify-content-center">
                <div>
                  <div className="review-card d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="rounded-circle overflow-hidden user-image-container mr-3">
                          <img
                            src={two}
                            className="img-fluid"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="text-left">
                          <h5 className="mb-0">Amit Sharma</h5>
                          <small className="text-muted">@amitsharma</small>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                    <p className="text-muted mt-3">
                    Thanks to Kannveer, our water tastes incredibly fresh. The purifier is reliable, and we no longer worry about contamination. It's a perfect fit for our home, and we're loving the healthy water!
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="container mt-3">
              <div className="row justify-content-center">
                <div>
                  <div className="review-card d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="rounded-circle overflow-hidden user-image-container mr-3">
                          <img
                            src={three}
                            className="img-fluid"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="text-left">
                          <h5 className="mb-0">Carlos Ramirez</h5>
                          <small className="text-muted">@carlosramirez</small>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                    </div>
                    <p className="text-muted mt-3">
                    We trust Kannveer for providing safe, clean water for our family. The purifier is easy to maintain, and the quality of water is excellent. Highly recommended!
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="container mt-3">
              <div className="row justify-content-center">
                <div>
                  <div className="review-card d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="rounded-circle overflow-hidden user-image-container mr-3">
                          <img
                            src={one}
                            className="img-fluid"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="text-left">
                          <h5 className="mb-0">Linda Chen</h5>
                          <small className="text-muted">@lindachen</small>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        {/* <i className="far fa-star" /> */}
                      </div>
                    </div>
                    <p className="text-muted mt-3">
                    Kannveer's water purifier has exceeded our expectations! The water quality is outstanding, and the machine is incredibly user-friendly. The customer service has also been top-notch.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
