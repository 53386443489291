import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import Sidebar from "./Sidebar";
import Form from "react-bootstrap/Form";
import Franchise from "./Franchise";
import Contactform from "./Contactform";

function Howitworks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  const textColor = {
    color : "#002c8f"
  }

  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate}  />
      {/* <Sidebar /> */}
      <section className="about-section">
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${"https://api.kanavneer.com/"+ banners.howItWorksImage})` }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{color:"#002c8f"}}>How it Works</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>How it Works</li>
            </ul>
          </div>
        </div>
      </section>

        {/* FAQ'S */}
        <Franchise/>

          {/* HOW IT WORKS */}
          <h2 className="text-center" style={textColor}>
          How it Works
        </h2>

        <div class="card-container">
          <div class="diddrtn">
            <i class="fas fa-cogs"></i>
            <h6>Select a Machine</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-list-alt"></i>
            <h6>Select a Plan</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-dollar-sign"></i>
            <h6>Pay the Bill as per Plan</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-truck"></i>
            <h6>Machine Door Delivery</h6>
          </div>

          <div class="diddrtn">
            <i class="fas fa-wrench"></i>
            <h6>Fix at Home and Drink Healthy Water</h6>
          </div>
        </div>

      </section>
      <Footer />
    </div>
  );
}

export default Howitworks;
