import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Contactform() {
  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    description: "",
    // currentBusiness: "",
  });

  const handleChange = (e) => {
    const myForm = { ...form };
    myForm[e.target.name] = e.target.value;
    setForm(myForm);
  };

  const addForm = (e) => {
    e.preventDefault();
    const bodydata = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      location: form.location,
      description: form.description,
      // currentBusiness: form.currentBusiness,
    };
    axios
      .post(
        "https://api.kanavneer.com/v1/kanavneelapi/web/enquiryform/addenquiry",
        bodydata
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setForm({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              description: "",
              currentBusiness: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          }
        }
      );
  };

  const textColor = {
    color: "#002c8f",
  };

  return (
    <div>
      <section className="contact-style-two mt-3">
        <h2 className="text-center mb-4" style={textColor}>
          Contact Information
        </h2>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-5 col-md-12 col-sm-12 info-column">
              <div className="info-inner">
                <ul className="info-list clearfix">
                 
                  <li>
                    <i className="fas fa-phone" />
                    <h5>Call for Help</h5>
                    <p>
                      <a href="tel:+91 7799018777">+91 77990 18777</a>
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-envelope-open" />
                    <h5>Email Drop Us</h5>
                    <p>
                      <a href="mailto:info@kanavneer.com">info@kanavneer.com</a>
                      <br />
                      <a href="mailto:narender@kanavneer.com">
                        narender@kanavneer.com
                      </a>
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <h5>Office Location</h5>
                    <p>
                      SHREE LUXMI GANESH UDYOG
                      <br /> PRIVATE LIMITED HNO 4-7-8/17/1/B
                      <br /> Flat No.103/A/1, Shivani Residency
                    </p>
                  </li>
                 
                </ul>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-sm-12 sidebar-column">
              <div className="faq-sidebar">
                <div className="form-inner">
                  <h3 className="text-center" style={textColor}>Contact Us</h3>
                  <form onSubmit={(e) => addForm(e)}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            type="text"
                            name="name"
                            value={form.name}
                            required
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Your Name"
                            style={borderColor}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group controlId="exampleForm.ControlInput2">
                          <Form.Control
                            type="email"
                            required
                            name="email"
                            value={form.email}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Your Email"
                            style={borderColor}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <Form.Group controlId="exampleForm.ControlInput3">
                          <Form.Control
                            type="tel"
                            required
                            name="phoneNumber"
                            value={form.phoneNumber}
                            onChange={(e) => handleChange(e)}
                            placeholder="Enter Your Mobile Number"
                            style={borderColor}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Group controlId="exampleForm.ControlInput4">
                          <Form.Control
                            type="text"
                            required
                            name="location"
                            placeholder="Enter Your Location"
                            value={form.location}
                            onChange={(e) => handleChange(e)}
                            style={borderColor}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        placeholder="Message"
                        as="textarea"
                        required
                        rows={3}
                        name="description"
                        value={form.description}
                        onChange={(e) => handleChange(e)}
                        style={borderColor}
                      />
                    </Form.Group>
                    <div className="form-group message-btn">
                      <button type="submit" className="theme-btn btn-one">
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
}

export default Contactform;
