import React from 'react'
import Header from './Header'
import Footer from './Footer'
import aboutbanner from '../assets/images/aboutbanner.jpg';
import banner from '../assets/images/banner/banner.png'
function Termsandconditions() {
  const textColor = {
    color: "#002c8f",
  };
  return (
    <div>
      <Header />
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${aboutbanner})` }}
      >
        <div
          className="shape"
        // style={{ backgroundImage: `url(${bannershape})` }}
        />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={textColor}>Terms And Conditions</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Terms And Conditions</li>
            </ul>
          </div>
        </div>
      </section>
  
      <section className="about-section">
        <div className="auto-container">
          <div className="row align-items-center clearfix">
          


            <h2 className=' mt-3 mb-4' style={textColor}>
              Terms and Conditions
            </h2>



            <h4 style={{ fontSize: '1.2rem' }}>1. Rental Agreement:</h4>
            <p>
              - The customer agrees to enter into a rental agreement with Kanavneer for the rental of a water purifier unit.
            </p>




            <h4 style={{ fontSize: '1.2rem' }}>2.Rental Period:</h4>
            <p>
              - The rental period begins on the date of installation and continues for the duration specified in the rental agreement.
            </p>

            <h4 style={{ fontSize: '1.2rem' }}>3.Rental Payments:</h4>
            <p>
              - The customer agrees to pay the rental fee as specified in the rental agreement. Rental payments are due [monthly/quarterly/annually] in advance.
            </p>
            <h4 style={{ fontSize: '1.2rem' }}>4.Installation and Maintenance:</h4>
            <p>
              - Kanavneer will install the water purifier unit at the customer's premises at no additional cost.
              -Kanavneer will provide regular maintenance and servicing of the water purifier unit during the rental period, including filter replacements and repairs due to normal wear and tear.
            </p>
            <h4 style={{ fontSize: '1.2rem' }}>5.Use of the Water Purifier:</h4>
            <p>
              - The customer agrees to use the water purifier unit solely for the purpose of purifying drinking water for personal or household use.
            </p>
            <p> - The customer agrees not to tamper with or modify the water purifier unit in any way.</p>
            <h4 style={{ fontSize: '1.2rem' }}>6.Care and Maintenance:</h4>
            <p>
              - The customer agrees to follow the care and maintenance instructions provided by Kanavneer to ensure the proper functioning of the water purifier unit.
            </p>
            <p> - The customer agrees to notify Kanavneer promptly of any issues or malfunctions with the water purifier unit.</p>
            <h4 style={{ fontSize: '1.2rem' }}>7. Liability:</h4>
            <p>
              - Kanavneer  shall not be liable for any damages or injuries resulting from the use or misuse of the water purifier unit.
            </p>
            <p>  - The customer agrees to indemnify and hold Kanavneer harmless from any claims or liabilities arising out of the customer's use of the water purifier unit.</p>
            <h4 style={{ fontSize: '1.2rem' }}>8. Termination:</h4>
            <p>
              -  The rental agreement will be considered terminated upon 90 days of unit being idle/not being recharged.
            </p>
            <p>  - Upon termination of the rental agreement, the customer agrees to return the water purifier unit to Kanavneer in good working condition, normal wear and tear excepted</p>
            <h4 style={{ fontSize: '1.2rem' }}>9. Governing Law:</h4>
            <p>
              - This agreement shall be governed by and construed in accordance with the laws of India.
            </p>
            <h4 style={{ fontSize: '1.2rem' }}>10. Entire Agreement:</h4>
            <p>
              - This agreement constitutes the entire understanding between the parties with respect to the subject matter hereof and supersedes all prior agreements and understandings, whether written or oral.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Termsandconditions