import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import shape3 from "../assets/images/shape/shape3.png";
import { Form } from "react-bootstrap";
import {
  FaSearch,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import Contactform from "./Contactform";

function CustomerStories() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const iconStyle = {
    margin: "0 auto",
    display: "block",
    fontSize: "2rem",
    color: "#002c8f", 
  };

  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate} />
      <section
        className="page-title centred"
        style={{
          backgroundImage: `url(${
            "https://api.kanavneer.com/" + banners.contactusImage
          })`,
        }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{ color: "#002c8f" }}>Contact Us</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Display Kanvneer Information */}
      {/* <section className="feature-section centred m-5" style={{ marginTop: "20px" }}>
  <div className="auto-container">
    <div data-wow-delay="00ms" data-wow-duration="1500ms">
      <div className="row clearfix">
        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
          <div className="card feature-block-one" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", transition: "transform 0.3s, box-shadow 0.3s", marginBottom: "15px" }}>
            <div className="card-body">
              <div className="shape" style={{ backgroundImage: `url(${shape3})` }} />
              <div className="icon-box" style={{ textAlign: "center", marginBottom: "15px" }}>
                <FaSearch style={iconStyle} /> 
              </div>
              <h4 style={{ textAlign: "center" }}>Search</h4>
              <p style={{ textAlign: "center" }}>
                Easily find what you’re looking for using our search bar with filters and suggestions.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
          <div className="card feature-block-one" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", transition: "transform 0.3s, box-shadow 0.3s", marginBottom: "15px" }}>
            <div className="card-body">
              <div className="shape" style={{ backgroundImage: `url(${shape3})` }} />
              <div className="icon-box" style={{ textAlign: "center", marginBottom: "15px" }}>
                <FaMapMarkerAlt style={iconStyle} /> 
              </div>
              <h4 style={{ textAlign: "center" }}>Location</h4>
              <p style={{ textAlign: "center" }}>
                Visit us at [Address]. See our location on the map and get directions.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
          <div className="card feature-block-one" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", transition: "transform 0.3s, box-shadow 0.3s", marginBottom: "15px" }}>
            <div className="card-body">
              <div className="shape" style={{ backgroundImage: `url(${shape3})` }} />
              <div className="icon-box" style={{ textAlign: "center", marginBottom: "15px" }}>
                <FaCalendarAlt style={iconStyle} /> 
              </div>
              <h4 style={{ textAlign: "center" }}>Availability</h4>
              <p style={{ textAlign: "center" }}>
                Check availability and book your slot directly through our calendar.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
          <div className="card feature-block-one" style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.1)", transition: "transform 0.3s, box-shadow 0.3s", marginBottom: "15px" }}>
            <div className="card-body">
              <div className="shape" style={{ backgroundImage: `url(${shape3})` }} />
              <div className="icon-box" style={{ textAlign: "center", marginBottom: "15px" }}>
                <FaPhoneAlt style={iconStyle} /> 
              </div>
              <h4 style={{ textAlign: "center" }}>Contact</h4>
              <p style={{ textAlign: "center" }}>
                Reach out to us via our contact form, email, or phone. We’re here to help!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

  {/* contact information */}
  <Contactform />

{/* map  */}
<div className="row">
  <div className="col-lg-12">
    <div id="google-map" className="google-map pb-2 mt-2"> 
      <iframe
        src="https://maps.google.com/maps?q=SHREE+LUXMI+GANESH+UDYOG+PRIVATE+LIMITED+++++++++++++++++++HNO+4-7-8%2F17%2F1%2FB+Flat+No.103%2FA%2F1%2C+Shivani+Residency%2C+Bypass+Road%2C+Sangareddy%2C+Telangana%2C+502001&t=&z=13&ie=UTF8&iwloc=&output=embed"
        style={{
          height: "400px",
          width: "100%",
          borderRadius: 20,
          marginLeft: "10px",
        }}
      />
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
}

export default CustomerStories;
