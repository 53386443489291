import React, { useEffect } from "react";

const styles = {
  articleContainer: {
    margin: "2rem auto",
    padding: "0rem",
    maxWidth: "1200px",
    width: "100%",
  },
  section: {
    marginBottom: "1.5rem",
    padding: "1.5rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  },
  sectionTitle: {
    color: "#002c8f",
    fontWeight: "bold",
    transition: "color 0.3s ease-in-out, text-shadow 0.3s ease-in-out",
  },
  fadeIn: {
    opacity: 0,
    transition: "opacity 1s ease-in-out",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    flexWrap: "wrap", // Allow cards to wrap to the next line on smaller screens
  },
  card: {
    flex: "1 0 30%", // Default to 30% width for larger screens
    margin: "0.5rem", // Margin around each card
    padding: "1.5rem",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out",
  },
  // Media query for mobile responsiveness
  '@media (max-width: 768px)': {
    card: {
      flex: "1 0 100%", // Full width on smaller screens
    },
  },
};

const Franchise = () => {
  useEffect(() => {
    document.querySelectorAll(".fade-in").forEach((el) => {
      el.classList.add("visible");
    });
  }, []);

  return (
    <article style={styles.articleContainer}>
      <div>
        <div className="animated-title">
          <h2>Franchise</h2>
        </div>
        <div style={styles.section} className="fade-in">
          <strong style={styles.sectionTitle}>1. Initial Investment:</strong>{" "}
          The initial investment includes the franchise fee, equipment,
          inventory, and setup costs. Exact figures will vary based on location
          and store size.
        </div>
        <div style={styles.section} className="fade-in">
          <strong style={styles.sectionTitle}>2. Training and Support:</strong>{" "}
          Kanvneer provides extensive training covering product knowledge, sales
          techniques, and operational procedures. Ongoing support is available
          to ensure franchisees have the tools they need for success.
        </div>
        <div style={styles.section} className="fade-in">
          <strong style={styles.sectionTitle}>
            3. Marketing and Advertising:
          </strong>{" "}
          Franchisees benefit from Kanvneer’s marketing strategies, including
          national and local advertising campaigns, promotional materials, and
          digital marketing support.
        </div>
        <div style={styles.section} className="fade-in">
          <strong style={styles.sectionTitle}>4. Exclusive Territories:</strong>{" "}
          To protect franchisees’ interests, Kanvneer offers exclusive
          territories. This ensures that each franchisee has a defined area in
          which to operate without internal competition.
        </div>
        <div style={styles.section} className="fade-in">
          <strong style={styles.sectionTitle}>
            5. Technology and Innovation:
          </strong>{" "}
          Kanvneer is dedicated to staying at the forefront of technology.
          Franchisees will have access to the latest water purification
          technologies and products.
        </div>
      </div>

      {/* Card Container */}
      {/* <div style={styles.cardContainer}>
        <div style={styles.card} className="fade-in">
          <h3>Card 1</h3>
          <p>Details about card 1...</p>
        </div>
        <div style={styles.card} className="fade-in">
          <h3>Card 2</h3>
          <p>Details about card 2...</p>
        </div>
        <div style={styles.card} className="fade-in">
          <h3>Card 3</h3>
          <p>Details about card 3...</p>
        </div>
      </div> */}

      <style>
        {`
          .animated-title {
            color: #002c8f;
            margin: 10px 0 0 10px;
            white-space: nowrap;
            overflow: hidden;
            width: 30em;
            animation: type 6s steps(60, end);
          }

          @keyframes type {
            from { width: 0; }
          }

          .fade-in.visible {
            opacity: 1;
          }

          @media (max-width: 768px) {
            .fade-in {
              flex: 1 0 100%; /* Full width on smaller screens */
            }
          }
        `}
      </style>
    </article>
  );
};

export default Franchise;
