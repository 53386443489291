import React from 'react'
import Header from './Header'
import Footer from './Footer'
import aboutbanner from '../assets/images/aboutbanner.jpg'

function Refundpolicy() {
  const textColor = {
    color: "#002c8f",
  };
  return (
    <div>
      <Header />
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${aboutbanner})` }}
      >
        <div
          className="shape"
        // style={{ backgroundImage: `url(${bannershape})` }}
        />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={textColor}>Refund policy</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Refund policy</li>
            </ul>
          </div>
        </div>
      </section>
      {/* <div className='card ml-5 mr-3 mt-3 mb-3'>
        <div className='cardbody ml-3 mt-4 mb-4'>
          <h2>Refund policy</h2>
          <h5>1. Rental Fees:</h5>
          <p> - Rental fees paid by the customer are non-refundable unless otherwise specified in the rental agreement.</p>

          <h5>2. Deposit:</h5>
          <p> - A security deposit may be required from the customer at the time of rental. The deposit will be refunded to the customer upon termination of the rental agreement, provided that the water purifier unit is returned in good working condition, normal wear and tear excepted.
          </p>
          <h5>3. Early Termination:</h5>
          <p>  - If the customer terminates the rental agreement before the end of the rental period, no refund of rental fees will be provided. Any remaining rental fees for the remainder of the rental period will still be due and payable by the customer.
          </p>
          <h5>4. Malfunction or Defect:</h5>
          <p> - In the event that the water purifier unit malfunctions or is defective, the customer may be entitled to a refund of rental fees for the period during which the unit was inoperable, subject to verification by Kanavneer.</p>
          <h5>5. Damage or Negligence:</h5>
          <p>  - If the water purifier unit is damaged due to the customer's negligence or misuse, no refund of rental fees will be provided. The customer may be liable for repair or replacement costs as determined by Kanavneer.</p>
          <h5>6. Refund Process:</h5>
          <p>- Requests for refunds must be submitted in writing to Kanavneer within 1 day of the event giving rise to the refund request.</p>
          <p> - Refunds will be processed within [number of days] of approval by Kanavneer and will be issued using the same method of payment used for the original transaction.</p>
          <h5>7. Exceptions:</h5>
          <p>- Refund policies may vary depending on the specific terms and conditions of the rental agreement and applicable laws in your jurisdiction.
          </p>
          <h5>8. Contact Information:</h5>
          <p>- For inquiries regarding refunds, please contact Kanavneer at info@kanavneer.com
          </p>
          <h5>9. Governing Law:</h5>
          <p> - This refund policy shall be governed by and construed in accordance with the laws of India.
          </p>
          <h5>
            10. Modification:</h5>
          <p> - Kanavneer reserves the right to modify or amend this refund policy at any time without prior notice.</p>
        </div>
      </div> */}
      <section className="about-section">
        <div className="auto-container">
          <div className="row align-items-center clearfix">
           


            <h2 className=' mt-3 mb-4' style={textColor}>
              Refund policy
            </h2>



            <h4 style={{ fontSize: '1.2rem' }}>1. Rental Fees:</h4>
            <p> - Rental fees paid by the customer are non-refundable unless otherwise specified in the rental agreement.</p>




            <h4 style={{ fontSize: '1.2rem' }}>2. Deposit:</h4>
            <p> - A security deposit may be required from the customer at the time of rental. The deposit will be refunded to the customer upon termination of the rental agreement, provided that the water purifier unit is returned in good working condition, normal wear and tear excepted.
            </p>

            <h4 style={{ fontSize: '1.2rem' }}>3. Early Termination:</h4>
            <p> - In the event that the water purifier unit malfunctions or is defective, the customer may be entitled to a refund of rental fees for the period during which the unit was inoperable, subject to verification by Kanavneer.</p>
            <h4 style={{ fontSize: '1.2rem' }}>4. Malfunction or Defect:</h4>
            <p> - In the event that the water purifier unit malfunctions or is defective, the customer may be entitled to a refund of rental fees for the period during which the unit was inoperable, subject to verification by Kanavneer.</p>
            <h4 style={{ fontSize: '1.2rem' }}>5. Damage or Negligence:</h4>
            <p>  - If the water purifier unit is damaged due to the customer's negligence or misuse, no refund of rental fees will be provided. The customer may be liable for repair or replacement costs as determined by Kanavneer.</p>
            <h4 style={{ fontSize: '1.2rem' }}>6. Refund Process:</h4>
            <p>- Requests for refunds must be submitted in writing to Kanavneer within 1 day of the event giving rise to the refund request.</p>
            <p> - Refunds will be processed within [number of days] of approval by Kanavneer and will be issued using the same method of payment used for the original transaction.</p>
            <h4 style={{ fontSize: '1.2rem' }}>7. Exceptions:</h4>
            <p>- Refund policies may vary depending on the specific terms and conditions of the rental agreement and applicable laws in your jurisdiction.
            </p>
            <h4 style={{ fontSize: '1.2rem' }}>8. Contact Information:</h4>
            <p>- For inquiries regarding refunds, please contact Kanavneer at info@kanavneer.com
            </p>
            <h4 style={{ fontSize: '1.2rem' }}>9. Governing Law:</h4>
            <p> - This refund policy shall be governed by and construed in accordance with the laws of India.
            </p>
            <h4 style={{ fontSize: '1.2rem' }}> 10. Modification:</h4>
            <p> - Kanavneer reserves the right to modify or amend this refund policy at any time without prior notice.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Refundpolicy