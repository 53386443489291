import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import aboutbanner from "../assets/images/aboutbanner.jpg";
import mision from "../assets/images/icons/mision.png";
import vision from "../assets/images/icons/vision.png";
import banner from "../assets/images/banner/banner.png";
import Sidebar from "./Sidebar";

function About() {
  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  return (
    <div>
      <Header onBannersData={handleUpdate} />
      {/* <Sidebar /> */}
      <section
        className="page-title centred"
        style={{
          backgroundImage: `url(${"https://api.kanavneer.com/"+ banners.aboutusImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px 20px",
          textAlign: "center",
          color: "#fff",
        }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{ color: "#002c8f" }}>About Us</h1>
            <ul
              className="bread-crumb clearfix"
              style={{
                listStyle: "none",
                padding: 0,
                margin: 0,
                textAlign: "center",
              }}
            >
              <li>
                <a href="/" style={{ color: "#fff", textDecoration: "none" }}>
                  Home
                </a>
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="about-section" style={{ padding: "20px" }}>
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12 image-column">
              <figure>
                <img
                  src={banner}
                  alt=""
                  style={{
                    width: "70%",
                    height: "auto",
                    borderRadius: "8px",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </figure>
            </div>
            <div className="col-lg-6 col-md-12 content-column">
              <div className="content_block_1">
                <div className="content-box">
                  <div className="sec-title" style={{ marginBottom: "20px" }}>
                    <h2>
                      We Always Want Safe and Healthy Water for Healthy Life.
                    </h2>
                  </div>
                  <div>
                    <h4>Introduction and Background of KanavNeer:</h4>
                    <p>
                      Water Purifier Solution Water, the lifeblood of our
                      planet, is not only vital for human survival but also
                      crucial for the intricate balance of our entire ecosystem.
                      From sustaining diverse species to nurturing plant life,
                      influencing weather patterns to supporting expansive
                      forests, water&#39;s impact is far-reaching and essential.
                    </p>
                    <h4>1. Challenges of Modernization</h4>
                    <p>
                      As the wheels of industrialization and urbanization turned
                      for a century, we witnessed not only progress but also a
                      decline in the natural purity we once took for granted.
                      This deterioration of water quality has had far-reaching
                      consequences on humanity&#39;s physical, mental, and
                      psychological well-being.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4>2. Harnessing Technology for Better Lives:</h4>
          <p>
            In this age of rapid technological advancement, the time is ripe to
            leverage innovation for the greater good. Amidst the pressing issues
            we face, ensuring access to clean and safe drinking water takes
            centre stage. Enter Narender Mittal, a prominent figure with over a
            decade of experience as a top dealer for leading water purification
            brands in India. Acknowledged for his exceptional commitment, he
            received the prestigious gold medal for top sales from none other
            than cricket icon Sachin Tendulkar in 2017.
          </p>
          <h4>3. Empathy and a Vision for Change:</h4>
          <p>
            Narender Mittal&#39;s journey is guided by empathy, particularly for
            the lower and middle sections of society. His resolve to create a
            positive impact became more pronounced during and after the covid 19
            pandemic, when the need for affordable, quality water became
            glaringly evident. Driven by the principles of the World Health
            Organization (WHO), Narender Mittal set forth to make healthy
            drinking water a reality for every household, even those with
            limited means. Our journey, led by Narender Mittal&#39;s remarkable
            vision, strives to bridge the gap between innovation and
            accessibility. With a relentless pursuit of excellence, we&#39;re
            dedicated to bringing pure, healthy drinking water to every
            household, forging a path towards a healthier, brighter future.
          </p>
          <h4>4. Innovation Meets Affordability:</h4>
          <p>
            Armed with a decade&#39;s worth of industry insight and a passion
            for change, Narender Mittal embarked on a new path. Drawing from his
            experience, he recognized the gaps in traditional service-based
            models. And thus, KanavNeer was born. We&#39;re not just another
            water purifier company. We&#39;re a team with a resolute vision to
            redefine the norm. KanavNeer stands for premium quality,
            affordability, and empowerment. While others bank on high service
            costs, we&#39;ve revolutionized the game, offering a superior
            product with only 20% of the service expenses.
          </p>
          <h4>5. Conclusion</h4>
          <p>
            KanavNeer represents more than a business venture; it&#39;s a
            commitment to humanity&#39;s well-being. Our journey, led by
            Narender Mittal&#39;s remarkable vision, strives to bridge the gap
            between innovation and accessibility. With a relentless pursuit of
            excellence, we&#39;re dedicated to bringing pure, healthy drinking
            water to every household, forging a path towards a healthier,
            brighter future.
          </p>
        </div>
      </section>

      {/* <div
        className="container"
        style={{ marginBottom: "25px", paddingTop: "30px" }}
      >
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div
              className="card"
              style={{
                boxShadow: "0 4px 8px rgba(173, 216, 230, 0.6)",
                transition: "box-shadow 0.3s ease-in-out",
                border: "none",
                marginBottom: "20px",
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <img
                    src={mision}
                    alt="Mission"
                    width="50px"
                    height="40px"
                    style={{ marginRight: "15px" }}
                  />
                  <div>
                    <h4>Mission:</h4>
                    <p>
                      Our mission is to provide accessible and affordable
                      drinking water solutions to improve the well-being of
                      people around the world.
                      <br /> We achieve this by developing innovative
                      purification technologies and collaborating with
                      healthcare professionals to deliver exceptional pure
                      water.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div
              className="card"
              style={{
                boxShadow: "0 4px 8px rgba(173, 216, 230, 0.6)",
                transition: "box-shadow 0.3s ease-in-out",
                border: "none",
                marginBottom: "20px",
              }}
            >
              <div className="card-body">
                <div className="d-flex align-items-start">
                  <img
                    src={vision}
                    alt="Vision"
                    width="50px"
                    height="40px"
                    style={{ marginRight: "15px" }}
                  />
                  <div>
                    <h4>Vision:</h4>
                    <p>
                      At KanavNeer, our mission is to make clean and safe
                      drinking water accessible to communities nationwide
                      through our innovative water purification solutions. We
                      are committed to providing reliable, eco-friendly, and
                      cost-effective water purification systems on a rental
                      basis and a franchise model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default About;
