import React from 'react'
import Header from './Header'
import Footer from './Footer'
import aboutbanner from '../assets/images/aboutbanner.jpg'
function Privacypolicy() {
  const textColor = {
    color: "#002c8f",
  };
  return (
    <div>
      <Header />
      <section
        className="page-title centred"
        style={{ backgroundImage: `url(${aboutbanner})` }}
      >
        <div
          className="shape"
        />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={textColor}>Privacy policy</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Privacy policy</li>
            </ul>
          </div>
        </div>
      </section>
    
      <section className="about-section">
        <div className="auto-container">
          <div className="row align-items-center clearfix">
            <h2 className=' mt-3 mb-4' style={textColor}>
              Refund Policy
            </h2>
            <p>Kanavneer is committed to protecting the privacy and security of our customers'personal information. This privacy policy outlines how we collect, use, and safeguard the information we collect from our customers.</p>


            <h4 style={{ fontSize: '1.2rem' }}>1. Information We Collect:</h4>
            <p> - We may collect personal information from customers when they rent a water purifier unit from us. This may include:</p>
            <p> - Name</p>
            <p> - Contact information (address, email, phone number)</p>
            <p>  - Payment information (credit card details, billing address)</p>
            <p>- Other information necessary for processing rental agreements and providing customer support. </p>
            <p> - We use the information collected from customers to:</p>
            <p> - Process rental agreements and payments</p>
            <p>- Install and service water purifier units</p>
            <p> - Communicate with customers regarding their rental agreements, billing, and customer support inquiries</p>
            <p> - Improve our products and services</p>
            <p> - Comply with legal and regulatory requirements</p>
            <h4 style={{ fontSize: '1.2rem' }}>3. Data Security:</h4>
            <p>- We implement appropriate technical and organizational measures to safeguard the personal information we collect from unauthorized access, disclosure, alteration, or destruction.</p>
            <p> - We restrict access to personal information to employees who need to know that information to provide products or services to our customers. </p>
            <h4 style={{ fontSize: '1.2rem' }}>4. Third-Party Disclosure:</h4>
            <p> - We do not sell, trade, or otherwise transfer customers' personal information to third parties unless we provide customers with advance notice or obtain their consent.</p>
            <p>  - We may disclose personal information to trusted third parties who assist us in operating our website, conducting our business, or servicing customers, provided that those parties agree to keep this information confidential.</p>
            <h4 style={{ fontSize: '1.2rem' }}>5. Data Retention:</h4>
            <p>  - We retain personal information for as long as necessary to fulfill the purposes outlined in this privacy policy unless a longer retention period is required or permitted by law.</p>
            <h4 style={{ fontSize: '1.2rem' }}> 6. Your Rights:</h4>
            <p>  - Customers have the right to request access to, correction, or deletion of their personal information. To exercise these rights, please contact us at info@kanavneer.com</p>
            <p> - Customers also have the right to opt-out of receiving marketing communications from us. </p>
            <h4 style={{ fontSize: '1.2rem' }}>7. Changes to This Privacy Policy:</h4>
            <p>  - We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website, and customers are encouraged to review this policy periodically.</p>
            <h4 style={{ fontSize: '1.2rem' }}>8. Contact Information:</h4>
            <p>- If you have any questions or concerns about this privacy policy or our practices regarding your personal information, please contact us at info@kanavneet.com</p>
            <h4 style={{ fontSize: '1.2rem' }}>9. Governing Law:</h4>
            <p>  - This privacy policy shall be governed by and construed in accordance with the laws of India.</p>
          </div>
        </div>
      </section>

      <Footer />
    </div >
  )
}

export default Privacypolicy