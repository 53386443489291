import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Plan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [banners, setBanners] = useState([]);
  const handleUpdate = (data) => {
    setBanners(data);
  };

  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow2(false);
  const handleShow = () => setShow2(true);

  const [form, setForm] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    location: "",
    description: "",
  });

  const handleChange = (e) => {
    const myForm = { ...form };
    myForm[e.target.name] = e.target.value;
    setForm(myForm);
  };

  const addForm = (e) => {
    e.preventDefault();
    const bodydata = {
      name: form.name,
      email: form.email,
      phoneNumber: form.phoneNumber,
      location: form.location,
      description: form.description,
      currentBusiness: form.currentBusiness,
    };
    axios
      .post(
        "https://api.kanavneer.com/v1/kanavneelapi/web/enquiryform/addenquiry",
        bodydata
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            setForm({
              name: "",
              email: "",
              phoneNumber: "",
              location: "",
              description: "",
              currentBusiness: "",
            });
            handleClose();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message);
          }
        }
      );
  };


  const textColor = {
    color: "#002c8f",
  };

  const borderColor = {
    border: "0.1px solid lightgray",
    backgroundColor: "white",
  };

  return (
    <div>
      <Header onBannersData={handleUpdate} />
      {/* <Sidebar /> */}
      <section
        className="page-title centred"
        style={{
          backgroundImage: `url(${
            "https://api.kanavneer.com/" + banners.allPlansImage
          })`,
        }}
      >
        <div className="shape" />
        <div className="auto-container">
          <div className="content-box">
            <h1 style={{ color: "#002c8f" }}>All Plans</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Plans</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="pricing-section">
        <h1 style={textColor} className="text-center m-3">
          Our Plans
        </h1>
        <Modal show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Book Now</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ margin: "0px", background: "#ebf3fa" }}>
          <div className="faq-sidebar">
            <div className="form-inner">
              <form
                onSubmit={(e) => {
                  addForm(e);
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    value={form.name}
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Your Name"
                    style={borderColor}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    value={form.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Your Email"
                    style={borderColor}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="tel"
                    required
                    name="phoneNumber"
                    value={form.phoneNumber}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Enter Your Mobile Number"
                    style={borderColor}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    required
                    name="location"
                    placeholder="Enter Your 
                    location"
                    value={form.location}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={borderColor}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder="Message"
                    required
                    as="textarea"
                    rows={3}
                    name="description"
                    value={form.description}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={borderColor}
                  />
                </Form.Group>
                <div className="form-group message-btn">
                  <button type="submit" className="theme-btn btn-one" style={{width:"60%"}}>
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
        <div className="auto-container">
          <div className="row justify-content-center align-items-stretch mt-5">
            {/* Pricing Card 1 */}
            <div className="col-lg-4 col-md-6 col-12 d-flex" style={{ marginBottom: "15px", marginLeft:"12px", marginTop:"-25px"}}>
              <div className="pricing-block-one d-flex flex-column h-100">
                <div className="pricing-table h-100 d-flex flex-column">
                  <div className="shape-layer" />

                  <div className="table-header">
                    <div className="price-box">
                      <div className="price-shape">
                        <div
                          className="shape-1"
                          style={{
                            backgroundImage:
                              "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-29.png)",
                          }}
                        />
                        <div
                          className="shape-2"
                          style={{
                            backgroundImage:
                              "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-30.png)",
                          }}
                        />
                      </div>
                      <h3>₹12999</h3>
                      {/* <span>Yearly</span> */}
                    </div>
                    <div className="title">
                        <h3 style={textColor}>Pro</h3>
                        <div>
                          <i
                            className="fas fa-star"
                            style={{ color: "gold" }}
                          ></i>
                          <b style={textColor}>One Time Fee</b>
                        </div>
                      </div>
                  </div>

                  <div className="table-content flex-grow-1 d-flex flex-column justify-between">
                    <ul className="feature-list clearfix">
                      <li>4 times general service free in a year</li>
                      <li>After 1 Year AMC 1999/-</li>
                      <li>1500/- worth alcone free</li>
                      <li>Up to 1 year parts replacements</li>
                    </ul>
                    <div className="table-footer mt-auto d-flex justify-content-center">
                      <a
                        className="theme-btn btn-one"
                        onClick={handleShow}
                        style={{ cursor: "pointer" }}
                      >
                        Book a Service
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Pricing Card 2 */}
            <div className="col-lg-4 col-md-6 col-12 d-flex" style={{ marginBottom: "15px", marginLeft:"12px", marginTop:"-25px"}}>
              <div className="pricing-block-one d-flex flex-column h-100">
                <div className="pricing-table h-100 d-flex flex-column">
                  <div className="shape-layer" />

                  <div className="table-header">
                    <div className="price-box">
                      <div className="price-shape">
                        <div
                          className="shape-1"
                          style={{
                            backgroundImage:
                              "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-29.png)",
                          }}
                        />
                        <div
                          className="shape-2"
                          style={{
                            backgroundImage:
                              "url(http://z.commonsupport.com/acuasafe/wp-content/uploads/2021/07/shape-30.png)",
                          }}
                        />
                      </div>
                      <h3>₹2999</h3>
                      <span className="text-danger" style={{fontSize:"10px"}}><b>Low Down Payment</b>
                        <p style={{fontSize:"10px"}}> <b>Per month  ₹999/-</b></p>
                        </span>
                    </div>
                    <div className="title">
                        <h3 style={textColor}>Pro Plus</h3>
                        <div>
                          <i
                            className="fas fa-star"
                            style={{ color: "gold" }}
                          ></i>
                          <b style={textColor}>(EMI available)</b>
                        </div>
                      </div>
                  </div>

                  <div className="table-content flex-grow-1 d-flex flex-column justify-between">
                    <ul className="feature-list clearfix">
                      <li>4 times general service free in a year</li>
                      <li>After 1 Year AMC 1999/-</li>
                      <li>1500/- worth alcone free</li>
                      <li>Up to 1 year parts replacements</li>
                    </ul>
                    <div className="table-footer mt-auto d-flex justify-content-center">
                      <a
                        className="theme-btn btn-one"
                        onClick={handleShow}
                        style={{ cursor: "pointer" }}
                      >
                        Book a Service
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-page-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 content-column">
              <div className="faq-content">
                <div className="sec-title">
                  <div
                    className="sec-title"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <h2 className="text-center" style={textColor}>
                      FAQ'S
                    </h2>
                  </div>
                </div>

                <Accordion defaultActiveKey="0" style={{marginTop:"-50px"}}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h4
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        How often should I replace the filters?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We recommend replacing the filters every 6 to 12 months,
                        depending on usage and environmental conditions.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h4
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Is the purifier noisy?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        No, our purifier is designed to operate quietly,
                        ensuring a peaceful environment while it cleans the air.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h4
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        Can I use the purifier overnight?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, our purifier is safe to use overnight. Its low
                        noise level and energy-efficient operation make it ideal
                        for continuous use.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      <h4
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        How do I clean the purifier?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Simply wipe the exterior with a soft, damp cloth. For
                        internal cleaning, follow the instructions in the user
                        manual.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      <h4
                        style={{
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        What size room does the purifier cover?
                      </h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Our purifier is suitable for rooms up to [specify square
                        footage], providing effective air purification
                        throughout.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Plan;
